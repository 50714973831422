import React from 'react';
import { BannerBlockTextColumnsTestId, defaultBlockItems, defaultHeadline } from './constants';

export interface ITextBlockItem {
  headline: React.ReactNode;
  content: React.ReactNode;
}

export interface Props {
  headline?: string;
  blockItems?: ITextBlockItem[];
  subHeadline?: React.ReactNode;
}

export const BannerBlockTextColumns: React.FC<Props> = ({
  headline = defaultHeadline,
  blockItems = defaultBlockItems,
  subHeadline,
}) => {
  return (
    <section className="container-fluid bg-asphalt py-5" data-testid={BannerBlockTextColumnsTestId}>
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-12 mx-auto">
            <h3
              className={`fw-500 fz-18 lh-187  ${
                subHeadline ? 'mb-3' : 'mb-5'
              } text-center text-primary text-uppercase`}
            >
              {headline}
            </h3>
          </div>
          {subHeadline && (
            <div className="col-12 text-center mb-5 ">
              <h4 className="fz-18 fw-500 ">{subHeadline}</h4>
            </div>
          )}
        </div>
        {blockItems && (
          <div className="row">
            {blockItems?.map((item, idx: number) => (
              <div key={idx} className="col-md-4 col-12 text-center mb-5 mb-md-0">
                <h4 className="fz-18 text-primary mb-4">{item.headline}</h4>
                {item?.content}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default BannerBlockTextColumns;
