import React from 'react';
import { OfficeLocationSectionTestId } from './constants';
import { StaticImage } from 'gatsby-plugin-image';

export const OfficeLocationSection: React.FC = () => {
  return (
    <section data-testid={OfficeLocationSectionTestId} className="bg-asphalt container-fluid py-6">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-3">
            <h3 className={'text-uppercase fz-18 text-primary text-center'}>OFFICE LOCATION</h3>
          </div>
          <div className="col-12 mb-5">
            <p className={'text-center'}>Denver</p>
          </div>
          <div className="col-12">
            <StaticImage
              placeholder={'tracedSVG'}
              src={'../../images/office-location/office-location-image.png'}
              alt={'Office Location'}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OfficeLocationSection;
