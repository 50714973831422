import * as React from 'react';
import { graphql } from 'gatsby';
import Default from '../../layouts/default';
import SEO from 'components/seo';
import { jobsPageBlockMapper } from '../../core/pageHelpers/jobs';
import BannerBlockTextColumns from '../../components/BannerBlockTextColumns';
import OpenJobsSection from '../../components/OpenJobsSection';
import TechWeBelieveInSection from '../../components/TechWeBelieveInSection';
import BestPlaceToWorkSection from '../../components/BestPlaceToWorkSection';
import OfficeLocationSection from '../../components/OfficeLocationSection';

const Jobs: React.FC<{ data: any }> = ({ data }) => {
  const aboutData: GatsbyTypes.ContentfulPageConnection = data.about;
  const aboutPage = aboutData.edges[0].node;
  const seo = aboutPage.seo;

  const blocks = Object.values(jobsPageBlockMapper(aboutPage.pageBlocks));
  return (
    <>
      <Default>
        <SEO data={seo} />
        {blocks.map((block) => block)}
        <BannerBlockTextColumns
          headline={`WHY STACKHAWK`}
          subHeadline={`We’re a venture backed startup building great technology and having lots of fun doing it.`}
          blockItems={[
            {
              headline: 'A Product to be Proud Of',
              content:
                'Be part of building a product that\n' +
                'you would want to use yourself.\n' +
                'Created for developers to help them\n' +
                'deploy more secure applications.',
            },
            {
              headline: 'Feel the Impact of Your Work\n',
              content: `As a fast moving software
startup, your daily contributions
will show and will help move the
company forward

`,
            },
            {
              headline: 'The Team You Want to be On\n',
              content: `Collaborate to solve
difficult problems, learn
from each other, and laugh
a lot in the process.

`,
            },
          ]}
        />
        <OpenJobsSection />
        <TechWeBelieveInSection />
        <BestPlaceToWorkSection />
        <OfficeLocationSection />
      </Default>
    </>
  );
};

export const query = graphql`
  {
    about: allContentfulPage(filter: { page: { eq: "Jobs" } }) {
      edges {
        node {
          seo {
            ...ContentfulSeo
          }
          ...defaultFields
        }
      }
    }
  }
`;

export default Jobs;
