import React from 'react';
import { defaultContent, defaultHeadline, JobsPreHeaderTestId } from './constants';
import PreHeaderBackgroundContainer from '../PreHeaderBackgroundContainer';
import { StaticImage } from 'gatsby-plugin-image';

export interface Props {
  headline?: React.ReactNode;
  content?: React.ReactNode;
}

export const JobsPreHeader: React.FC<Props> = ({ content = defaultContent, headline = defaultHeadline }) => {
  return (
    <section data-testid={JobsPreHeaderTestId} className="container-fluid px-0">
      <PreHeaderBackgroundContainer>
        <div className="container pt-8 pb-5">
          <div className="row flex-column-reverse flex-md-row ">
            <div className="col-md-6 col-12 mx-auto bg-asphalt py-5 px-5">
              <h2 className="mb-4">{headline}</h2>
              <p className="fz-18 lh-187">{content}</p>
            </div>
            <div className="col-md-6 col-12 mx-auto  ">
              <StaticImage
                placeholder={'tracedSVG'}
                src="../../images/stackhawk-founders.png"
                alt="Stackhawk Founders Image"
              />
            </div>
          </div>
        </div>
      </PreHeaderBackgroundContainer>
    </section>
  );
};

export default JobsPreHeader;
