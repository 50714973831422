import React from 'react';
import './style.scss';
import { preHeaderBackgroundContainerTestId } from './constants';

export const PreHeaderBackgroundContainer: React.FC = ({ children }) => {
  return (
    <section
      data-testid={preHeaderBackgroundContainerTestId}
      className="pre-header-background-container container-fluid justify-content-center align-items-center px-0"
    >
      {children}
    </section>
  );
};

export default PreHeaderBackgroundContainer;
