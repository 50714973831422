import React from 'react';
import { OpenJobsSectionTestId, defaultPerks } from './constants';
import ExternalLink from '../ExternalLink';

export interface IPerk {
  headline?: React.ReactNode;
  content?: React.ReactNode;
}
export interface Props {
  perks?: IPerk[];
}

export const OpenJobsSection: React.FC<Props> = ({ perks = defaultPerks }) => {
  return (
    <section data-testid={OpenJobsSectionTestId} className={'container-fluid py-5'}>
      <div className="container">
        <div className="row mb-8">
          <div className="col-12  mb-5 text-center">
            <h3 className={'text-uppercase fz-18 text-primary'}>Open Jobs</h3>
          </div>
          <div className="col-12 text-center mb-5">
            <ExternalLink className="btn btn-primary btn-lg" href={`https://jobs.lever.co/stackhawk`}>
              View Open Positions
            </ExternalLink>
          </div>
          <div className="col-12 bg-asphalt rounded pt-8 pb-4 px-4 text-center">
            <h4 className="fz-24 text-center mb-4">Don’t see a position that fits your skills?</h4>
            <p>
              StackHawk is on a mission to deliver industry leading AppSec tooling to the hands of Developers.
              <br /> Drop us a line and let us know how you can help!
            </p>
            <ExternalLink className="btn btn-outline-primary" href={`mailto:jobs@stackhawk.com`}>
              Contact Us
            </ExternalLink>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12  mb-5 text-center">
            <h3 className={'text-uppercase fz-18 text-primary'}>the perks</h3>
          </div>
          <div className="row">
            {perks.map((perk, idx) => (
              <div className="col-12 col-md-4 mb-5" key={idx}>
                <p className="mb-1">
                  <strong>{perk.headline}</strong>
                </p>
                <p>{perk.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OpenJobsSection;
