import React from 'react';
import { BestPlaceToWorkSectionTestId } from './constants';
import './style.scss';
import { StaticImage } from 'gatsby-plugin-image';

export const BestPlaceToWorkSection: React.FC = () => {
  return (
    <section data-testid={BestPlaceToWorkSectionTestId} className="container-fluid py-6 best-places-to-work">
      <div className="container bg-asphalt rounded-20 py-5">
        <div className="row">
          <div className="col-12 mb-5">
            <h3 className="text-center fz-28 lh-140">
              We’re thrilled to be recognized in Built In's <br className="d-none d-md-block" /> 2021{' '}
              <span className="text-primary">Best Places to Work</span> in Colorado
            </h3>
          </div>
          <div className="col-md-8 col-12 mx-auto">
            <div className="row">
              <div className="col text-center">
                <StaticImage
                  placeholder={'tracedSVG'}
                  src={
                    '../../images/best-places-badges/2021-best-places-to-work-small-companies-p1quqoobscv8jt3mv3tk8mhhy0bg1fysxjmjussruo.png'
                  }
                  alt={'2021 best places to work colorado small companies'}
                />
              </div>
              <div className="col text-center">
                <StaticImage
                  placeholder={'tracedSVG'}
                  src={
                    '../../images/best-places-badges/2021-best-places-to-work-best-paying-p1quqzye2daof4n918p32jn12mrult7kz3gdm4c1s0.png'
                  }
                  alt={'2021 best places to work colorado best paying companies'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestPlaceToWorkSection;
