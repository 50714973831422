import React from 'react';
import PageHeader from '../../components/PageHeader';
import { genericPageBlockMapper } from './genericPageBlockMapper';
import JobsPreHeader from '../../components/JobsPreHeader';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const jobsPageBlockMapper = (blocks: GatsbyTypes.ContentfulPage['pageBlocks']) => {
  const components = genericPageBlockMapper(blocks);
  blocks?.forEach((block, index: number) => {
    switch (block?.internal.type) {
      case 'ContentfulComponentPageHeader':
        block = block as GatsbyTypes.ContentfulComponentPageHeader;
        components[index] = (
          <PageHeader
            key={index}
            textTop={block?.textTop || ''}
            textMiddle={block?.textMiddle}
            textBottom={block?.textBottom}
            backgroundImage={block?.backgroundImage?.gatsbyImageData}
            subHeaderCls={'bg-dark'}
            subHeader={<JobsPreHeader />}
          />
        );
        break;

      default:
        break;
    }
  });

  return components;
};
