export const OpenJobsSectionTestId = 'OpenJobsSection';
export const defaultPerks = [
  {
    headline: 'Competitive Salary',
    content: 'All of the startup fun with more than ramen for dinner.',
  },

  {
    headline: '100% Paid Insurance',
    content: 'We support your health. Medical, Dental, and Vision fully covered.',
  },

  {
    headline: 'Take What You Need Vacation',
    content: 'Unplug, recharge, and come back refreshed.',
  },

  {
    headline: 'Equity Ownership',
    content: 'Own a stake in the company that we are building together.',
  },

  {
    headline: 'Commuter Benefits',
    content: 'Parking or RTD – take your pick and we’ll cover it.',
  },

  {
    headline: 'Breakfast, Coffee, & Snacks',
    content: 'Fuel your best work with provided food and beverages.',
  },

  {
    headline: 'Diversity & Inclusion',
    content: 'Diversity in our team is encouraged and celebrated.',
  },

  {
    headline: 'Great Team',
    content: 'Do great work with a healthy dose of laughter & camaraderie.',
  },
];
