import React from 'react';
import { TechWeBelieveInSectionTestId } from './constants';
import { StaticImage } from 'gatsby-plugin-image';

export interface Props {
  techLogos?: string[];
}

export const TechWeBelieveInSection: React.FC<Props> = () => {
  return (
    <section data-testid={TechWeBelieveInSectionTestId} className={'container-fluid bg-asphalt py-6'}>
      <div className="container">
        <div className="row">
          <div className="col-12 mb-5">
            <h3 className={'text-uppercase fz-18 text-primary text-center'}>THE TECH IN WHICH WE BELIEVE AND WORK</h3>
          </div>
          <div className="col-md-10 col-12 mx-auto">
            <div className="row">
              <div className="col-md col-4 mb-4 mb-md-0">
                <StaticImage
                  placeholder={'tracedSVG'}
                  src={'../../images/techs-we-believe/aws-logo.svg'}
                  alt={'aws services logo'}
                />
              </div>
              <div className="col-md col-4 mb-4 mb-md-0">
                <StaticImage
                  placeholder={'tracedSVG'}
                  src={'../../images/techs-we-believe/docker-logo.svg'}
                  alt={'docker logo'}
                />
              </div>
              <div className="col-md col-4 mb-4 mb-md-0">
                <StaticImage
                  placeholder={'tracedSVG'}
                  src={'../../images/techs-we-believe/kotlin-logo.svg'}
                  alt={'kotlin logo'}
                />
              </div>
              <div className="col-md col-4 mb-4 mb-md-0">
                <StaticImage
                  placeholder={'tracedSVG'}
                  src={'../../images/techs-we-believe/kubernetes-logo.svg'}
                  alt={'kubernetes logo'}
                />
              </div>
              <div className="col-md col-4 mb-4 mb-md-0">
                <StaticImage
                  placeholder={'tracedSVG'}
                  src={'../../images/techs-we-believe/react-logo.svg'}
                  alt={'reactjs logo'}
                />
              </div>
              <div className="col-md col-4 mb-4 mb-md-0">
                <StaticImage
                  placeholder={'tracedSVG'}
                  src={'../../images/techs-we-believe/zap-logo.svg'}
                  alt={'zap scanner logo'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechWeBelieveInSection;
